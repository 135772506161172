/* You can add global styles to this file, and also import other style files */
// @import "primeicons/primeicons.css";

* {
    // margin: 0;
    // padding: 0;
    box-sizing: border-box;
}

.page {
    flex: 1;
    overflow-y: scroll;
    height: 100vh;
}

html {
    font-size: 14px;
}

body {
    font-family: var(--font-family);
    font-weight: normal;
    background: #eff1f6;
    color: var(--text-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
}

.font-size-zero {
    font-size: 0;
}

// copy from https://stackoverflow.com/questions/65776864/is-there-a-solution-to-style-scrollbars-globally
/* Scroll bar stylings */
::-webkit-scrollbar {
    width: 6px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: #b5bad9;
    border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #a0a7df;
}
